
  
  .ant-pagination {
    /* border: 1px solid #dee2e6; */
    border-radius: 0.25rem;
  }
  
  /* .ant-pagination .ant-pagination-item {
    margin: 0;
    border-top: none;
    border-bottom: none;
    border-radius: 0;
    color: #00425f;
  } */
  
  .ant-pagination .ant-pagination-item:hover {
    border-color: #dee2e6;
  }
  
  .ant-pagination .ant-pagination-item-active {
    background: #00425f;
    border-color: #00425f;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  
  .ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  
  .ant-pagination .ant-pagination-item-active a:hover {
    color: #fff;
  }
  
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    padding: 0 1rem;
    color: #00425f;
    background: #fff;
    margin: 0;
    border: 1px solid #dee2e6;
  }

  .ant-pagination .ant-pagination-prev{
    margin-right: 5px;
  }
  
  .ant-pagination .ant-pagination-prev.ant-pagination-disabled,
  .ant-pagination .ant-pagination-next.ant-pagination-disabled {
    color: #6c757d;
  }
  
  .dropdown-menu-kam,
  .dropdown-menu-kam2 {
    background: #fff;
  }
  
  .dropdown-item {
    color: #00425f;
  }
  
  .dropdown-item:hover {
    background: #f8f9fa;
    cursor: pointer;
  }
  
  .openModal {
    display: block;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  
  .openModal .isw-sideBarNav {
    width: 250px;
  }
  
  label[for='cssTarget'] {
    margin: 4px 0 0 8px;
  }
  
  label[for='cssTarget'][data-shrink='true'] {
    color: #00425f;
  }
  
  .form-field__input[id='cssTarget']:hover {
    border: none !important;
  }
  
  .daterangepicker .ranges li.active {
    background-color: #00425f !important;
  }
  
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: #00425f !important;
  }
  
  .isw-valign--middle {
    word-break: break-all;
    width: 100%;
  }
  
  .progress-bar {
    background: #00425f !important;
  }
  
  .activeCard {
    background: #02567C !important;
  }
  
  .activeCard,
  .activeCard span {
    color: white;
  }
  
  .react-bootstrap-daterangepicker-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  
  .isw-table--body > span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .contract-detail .ant-spin-nested-loading {
    height: unset;
  }
  /*# sourceMappingURL=kamsi.css.map */